import React, { useEffect, useState } from 'react'
import { Spin, message } from 'antd';
import Editor from '../components/Editor';
import { getAppData, postCall } from '../components/CommonApiCall';


function TermsOfService() {
  const [text, setText] = useState('');
  const editor = React.useRef(null)

  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')

  useEffect(() => {
    getData()
  }, [])

  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  // to fetch the data
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setId(response?.data?.data?.id)
      const htmlData = htmlDecode(response?.data?.data?.terms)
      setText(htmlData)
    }
    const failureCb = (response) => {
      setLoading(false)
    }
    getAppData("terms_condition", successCb, failureCb);
  }

  //**** Update The Data ***//
  const handleChange = (value) => {
    setLoading(true)
    // const decodedContent = value.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    // console.log('decodedContent', decodedContent)
    setText(value);
    let data = {
      _id: id,
      type: "terms",
      content: value,
    }
    console.log("terms data", data)
    let url = '/admin/update_app_data'
    // return false;
    const successCb = (res) => {
      setLoading(false)
      console.log("temrs succes update", res)
      getData()
      message.success("Data Updated Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  };

  return (
    <div className='Maincard p-3'>
      <h3>Terms and Conditions</h3>
      <div>
      </div>
      {!loading && <Editor text={text} handleUpdate={handleChange} />}
      {loading && <Spin size="large" className='loader' />}
    </div>
  )
}


export default TermsOfService
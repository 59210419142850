import { Typography, Button, Form, Upload, Input, Spin, Popconfirm } from 'antd'
import upload from '../assets/images/upload.png'
import React, { useEffect, useRef, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import bluedot from '../assets/images/bluedot.svg';
import blackdot from '../assets/images/balckdot.svg';
import path_blue from '../assets/images/Path _blue.svg';
import path_black from '../assets/images/Path_black.svg';
import { callDelete, getDataCallContact, postCall, postUpdateIntroductory } from '../components/CommonApiCall';
import { useForm } from 'antd/es/form/Form';
import { fileUrl } from '../constants/const';
import { RiDeleteBin4Fill } from "react-icons/ri";
import Editor from '../components/Editor';
import ReactQuill from 'react-quill';
const { TextArea } = Input;


const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

function Introductory() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [form] = Form.useForm();
  const imageRef = useRef(null)
  const [selectedScreeen, setSelectedScreeen] = useState(0);
  const [data, setData] = useState([]);
  const [uploadUrl, setUploadUrl] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState('')
  const [editThumbnail, setEditThumbnail] = useState('')
  const [description, setDescription] = useState('')


  useEffect(() => {
    setUploadUrl(data[selectedScreeen]?.media)
    form.setFieldsValue({
      upld: data[selectedScreeen]?.media,
      title: data[selectedScreeen]?.title,
      description: data[selectedScreeen]?.desc,
    })
    setDescription(data[selectedScreeen]?.desc)
  }, [selectedScreeen, data])

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if (editThumbnail) {
      if (typeof editThumbnail !== "string") {
        (async function () {
          let abc = URL.createObjectURL(editThumbnail)
          console.log("abc", abc)
          setSelectedThumbnail(abc)
        })()
      }
    }
  }, [editThumbnail])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      console.log("res intro", response)
      setData(response?.data?.data);
    }
    const failureCb = (response) => {
      setLoading(false)

    }
    getDataCallContact("/admin/get_intro_screens", successCb, failureCb);
  }

  const onFinish = (values) => {
    console.log('Success:', values);
    if (!(values?.title).trim()) { message.error("Title is required") }
    if (!(values?.description.trim())) { message.error("Description is required") }
    let datas = {
      _id: data[selectedScreeen]?.id,
      title: values?.title,
      desc: values?.description,
      media: editThumbnail,
    }
    console.log("datas", datas)
    // return 
    let url = '/admin/update_intro_screens'
    const successCb = (res) => {
      setLoading(false)
      console.log(res)
      setEditThumbnail(null);
      setSelectedThumbnail(null);
      message.success("Data Updated Successfully")
      form.resetFields()
      getData()
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postUpdateIntroductory(datas, url, successCb, failureCb)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleChange = (info) => {
    console.log("information", info)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    getBase64(info.file.originFileObj, (url) => {
      console.log("url", url)
      setImageUrl(url);
      setLoading(false);
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // delete image
  const handleDelete = (id) => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      console.log("res intro", response)
      message.success(response?.data?.message)
      getData()
    }
    const failureCb = (response) => {
      setLoading(false)
      message.error(response?.data?.message)
    }
    callDelete(`/admin/delete_intro_screen?_id=${id}`, successCb, failureCb);
  }

  console.log("selectedScreeen", selectedScreeen)

  const handleChangeDesc = (value) => {
    setDescription(description);
    form.setFieldValue('description', value)
  }


  function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  return (
    <div className='p-3 Maincard'>

      <Form
        form={form}
        key={selectedScreeen}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ remember: true, }}
      >
        <Typography.Title level={3} className='mb-3 fw-bold' >Introductory</Typography.Title>
        <div className='p-2 my-2 d-flex align-items-center' style={{ backgroundColor: "#E1E6FC", height: "60px" }} >
          <h6 className='ml-1'> &nbsp;&nbsp;
            <span onClick={() => {
              setSelectedScreeen(0); setImageUrl('');
              setEditThumbnail(null);
              setSelectedThumbnail(null);
            }}>
              <img src={selectedScreeen == 0 ? bluedot : blackdot} alt="" /> &nbsp;<span style={{ color: `${selectedScreeen == 0 ? "blue" : "black"}`, cursor: "pointer" }}>Screen 1 </span></span>
            &nbsp;&nbsp; <img src={path_black} alt="" />
            &nbsp;&nbsp;
            <span onClick={() => {
              setSelectedScreeen(1); setImageUrl(''); setEditThumbnail(null);
              setSelectedThumbnail(null);
            }}> <img src={selectedScreeen == 1 ? bluedot : blackdot} alt="" />&nbsp;<span style={{ color: `${selectedScreeen == 1 ? "blue" : "black"}`, cursor: "pointer" }}> Screen 2 </span></span>
            &nbsp;&nbsp; <img src={path_black} alt="" /> &nbsp;&nbsp;
            <span onClick={() => {
              setSelectedScreeen(2); setImageUrl(''); setEditThumbnail(null);
              setSelectedThumbnail(null);
            }}> <img src={selectedScreeen == 2 ? bluedot : blackdot} alt="" />&nbsp;<span style={{ cursor: "pointer", color: `${selectedScreeen == 2 ? "blue" : "black"}`, }}>Screen 3</span></span>
          </h6>

        </div>
        {data.length > 0 && console.log("data check", data, selectedScreeen)}
        {data[selectedScreeen]?.title &&
          <>
            <div className='p-2'>
              <h6 className='fw-bold mb-2'>Upload Picture</h6>
              <div className='d-flex'>
                <Form.Item name="upld"
                  // initialValue={imageUrl?? ""}
                  labelCol={{ span: 24 }}>
                  {(imageUrl || upload) && <img id="selectedImage" src={selectedThumbnail ? selectedThumbnail : upload} alt="" onClick={() => imageRef.current.click()} style={{ height: '150px', width: '200px', cursor: "pointer", objectFit: "contain" }} />}
                  <input type="file" accept="image/*" ref={imageRef} style={{ display: 'none' }} onChange={(event) => setEditThumbnail(event.target.files[0])} ></input>
                </Form.Item>
                <div style={{ position: "relative" }}>
                  <Popconfirm
                    placement="topLeft"
                    title={"Are you sure, you want to delete?"}
                    // description={description}
                    okText="Yes"
                    onConfirm={() => handleDelete(data[selectedScreeen]?.id)}
                    cancelText="No"
                  >
                    {uploadUrl && <div style={{ cursor: "pointer", position: "absolute", right: "6px", top: "5px", width: "28px", height: "28px", backgroundColor: "white", borderRadius: "50%" }}>
                      <RiDeleteBin4Fill size={"medium"} style={{ cursor: "pointer", position: "absolute", right: "4px", top: "4px", width: "20px", height: "20px", color: "#454CC9" }} />
                    </div>}
                  </Popconfirm>
                  {uploadUrl && <div className=''>
                    <img src={`${fileUrl}${uploadUrl}`} alt="Image" className='ms-3' style={{ height: '150px', width: '200px', pointerEvents: "none", objectFit: "contain", backgroundColor: "rgba(215,215,215,1)", borderRadius: "10px" }} />
                  </div>
                  }
                </div>
              </div>
              <div className='p-2'>
                <h6 className='fw-bold'>Title</h6>
                <Form.Item name="title">
                  <Input placeholder="Type Title Here." className='w-50' />
                </Form.Item>
              </div>
              <div className='p-2'>
                <h6 className='fw-bold'>Description</h6>
                <Form.Item name="description">
                  <ReactQuill
                    theme="snow"
                    onChange={(value) => handleChangeDesc(value)}
                    modules={Editor.modules}
                    formats={Editor.formats}
                    placeholder={'Type your Description Here.'}
                    // style={{ minHeight: '40px', overflowY: 'auto', height: 'auto' }}
                    preserveWhitespace
                  />
                </Form.Item>
              </div>
              <div className='p-2'>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="button_common w-25" >
                    Update
                  </Button>
                </Form.Item>
              </div>
            </div>
          </>
        }
      </Form>
      {loading && <Spin size="large" className='loader' />}
    </div>
  )
}

export default Introductory
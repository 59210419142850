import { Button, Input, Radio, Typography } from 'antd'
import React, { useState } from 'react'
const { TextArea } = Input;

function CustomNotification() {
  const [value, setValue] = useState();
  const [customNotification, setCustomNotification] = useState();
  const handleInputNotification = (e) => {
    console.log("check", e.target.value);
    setCustomNotification(e.target.value)
  }
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  return (
    <div className='p-4 Maincard'>
      <Typography.Title level={3} className='mb-3 fw-bold' >Custom Notification</Typography.Title>
      {/* <Typography.Title level={5}>Select User Type</Typography.Title> */}
      {/* <div className='fw-bold mb-1'>Select User Type</div>
      <Radio.Group onChange={onChange} value={value} buttonPaddingInline={30} dotSize={20}>
        <Radio className="mt-2" value="viewer">Viewer</Radio>
        <Radio className="me-sm-3 mx-lg-3" value="host">Host</Radio>
        <Radio className="me-sm-3 mx-lg-3" value="coaches">Coaches</Radio>
        <Radio className="" value="allusers">All Users</Radio>
      </Radio.Group> */}
      <div className='mt-3'>
        {/* <Input className='input_width' onChange={handleInputNotification} placeholder='Type your notification here' /> */}
        <TextArea rows={6} className='w-50' placeholder="Type Your Notification Here" style={{boxShadow:" 6px 8px 12px 0px #d9d9d9"}}/>
        </div>
      <div className='mt-3'>
        <Button type="primary" htmlType="submit" className="button_common mt-5 w-25" >
        Send
      </Button>
      </div>
    </div>
  )
}

export default CustomNotification
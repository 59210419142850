import { baseUrl } from "../constants/const";
import { apiCall } from "./ApiCall";

// ***** Common function postCall for updation , delete, status change *******//
export async function postCall(data, url, successCb, failureCb) {
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${url}`,
        data,
    };

    console.log("config ",config)
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err.message);
    }
    console.log("123456",response, response?.data)
    if (response?.status == 200) {
        return successCb(response)
    } else return failureCb(response)
  }

export async function postCallResetPassword(data, url, successCb, failureCb) {
    let token = localStorage.getItem('access_token')
    console.log("token ", token)
    let config = {
        method: "post",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${url}?token=${token}`,
        data,
    };
    console.log("url config", config)
    // return false
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err.message);
    }
    console.log("reset",response, response?.data)
    if (response?.status == 200) {
        return successCb(response)
    } else return failureCb(response)
  }

  //** Called to fetch the users data record **//
 export const getDataCall = async (page, limit, keyword, get_url=null, successCb, failureCb, type = '') => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}?page=${page}&limit=${limit}&keyword=${keyword}`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }
    if (response?.status == 200) {
        successCb(response)
    }else {
        failureCb(response)
    }
}
 export const getDataCallContact = async (get_url, successCb, failureCb) => {
    let config = {
        method: "get",
        url: `${baseUrl}${get_url}`,
        headers: { 'Content-Type': 'application/json' },
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }console.log("responese api", response)
    if(response?.status == 200)  {
        successCb(response)
    }else {
        failureCb(response)
    }
}
 export const getDataCallResult = async (page, limit, keyword, get_url=null, successCb, failureCb, type = '') => {
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}?page=${page}&limit=${limit}&keyword=${keyword}`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }
    console.log("response123123123", response?.data?.[0]?.results?.length)
        if(response?.status == 200)  {
        successCb(response)
    }else {
        failureCb(response)
    }
}
 export const getDataReports = async (page, limit, keyword, get_url=null, successCb, failureCb, type = 'user') => {
    console.log("report type" , type)
    // return false;
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${get_url}?page=${page}&limit=${limit}&reportType=${type}&populate=reportedTo,stream,commentBy,reportedBy`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }
    console.log("response123123123", response?.data?.[0]?.results?.length)
    // if (response?.data?.[0]?.results?.length > 0) {
        if(response?.status == 200)  {
        successCb(response)
    }else {
        failureCb(response)
    }
}
 export const getDataDashboard = async (page, limit, get_url=null,keyword, successCb, failureCb) => {
    let url;
    if(keyword) url =  `${baseUrl}${get_url}?page=${page}&limit=${limit}&search=${keyword}`
    else url = `${baseUrl}${get_url}?page=${page}&limit=${limit}`
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        // url: `${baseUrl}${get_url}?page=${page}&limit=${limit}`,
        url: url,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }
    console.log("response123123123", response?.data?.[0]?.results?.length)
    // if (response?.data?.[0]?.results?.length > 0) {
        if(response?.status == 200)  {
        successCb(response)
    }else {
        failureCb(response)
    }
}
//  Method to Get Data for terms & condition, about us , privacy policy
export const getAppData = async (type, successCb, failureCb) =>{
    let config = {
        method: "get",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/admin/get_app_data`,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }
    console.log("response getAppData",response)
    if (response?.status == 200) {
        console.log("success", response)
        successCb(response)
    }else {
        failureCb(response)
    }
}
export async function callDelete( url, successCb, failureCb) {
    let config = {
        method: "delete",
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}${url}`,
    };
    console.log("config ",config)
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
        console.log(err.message);
    }
    console.log("123456",response, response?.data)
    if (response?.status == 200) {
        return successCb(response)
    } else return failureCb(response)
  }

  export const postUpdateIntroductory = async (data,get_url, successCb, failureCb) => {
    let formData = new FormData();
       if(data?.media) formData.append("media", data?.media);
        formData.append("_id", data?._id);
        formData.append("title", data?.title);
        formData.append("desc", data?.desc);
      console.log("formdata",data, formData)
    //   return false;
    let config = {
        method: "post",
        headers: { 'Content-Type': 'multipart/form-data', },
        url: `${baseUrl}${get_url}`,
        data:formData,
    };
    let response;
    try {
        response = await apiCall(config);
    } catch (err) {
       console.log(err)
    }
        if(response?.status == 200)  {
        successCb(response)
    }else {
        failureCb(response)
    }
}
 

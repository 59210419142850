import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from 'antd';

function Editor({ text, handleUpdate }) {
  const [editValue, setEditValue] = useState(text)
  return (
    <div className='mb-5'>
      <ReactQuill
        value={editValue ? editValue : text}
        theme="snow"
        onChange={(value) => setEditValue(value)}
        modules={Editor.modules}
        formats={Editor.formats}
        placeholder={'Write something...'}
        preserveWhitespace
      />
      <Button type='primary' className='px-3 purpleButton' style={{ float: "right", margin: "10px 0px" }} onClick={() => handleUpdate(editValue)}>Update</Button>
    </div>
  )
}

Editor.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link'],  // Ensure the link button is available in the toolbar
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],
    ['clean'],
  ],
};

Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];
export default Editor
import { Button, Col, Row, Table, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import barchart from '../assets/images/Bar Chart.svg'
import barchart1 from '../assets/images/Bar Chart 1.svg'
import barchart2 from '../assets/images/Bar Chart 2.svg'
import refresh from '../assets/images/md-refresh.svg'
import refresh1 from '../assets/images/refresh.svg'
import user from '../assets/images/users-1.png'
import line from '../assets/images/Line.svg'
import calender from '../assets/images/calendar-week-fill.svg'
import arrow_down from '../assets/images/ArrowDown.svg'
import map from '../assets/images/map (1).svg'
import usa from '../assets/images/Usa@2x.png'
import StackedAreaChart from '../components/StackAreaChart'
import logoDel from '../assets/images/delete.png'
import { getDataCallContact } from '../components/CommonApiCall'
import { capitalizeFirstLetter } from '../utils/UtilityFunctions'


const dataSource = [
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
  { name: 'John', email: 'johndoe911@gmail.com' },
]

function Overview() {
  const [loading, setLoading] = useState();
  const [users, setUsers] = useState([]);
  const [totalValues, setTotalValues] = useState({});

  useEffect(() => {
    getData();
  }, [])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      console.log("get user list", response)
      setTotalValues({
        users: response?.data?.data?.total_users,
        tags: response?.data?.data?.total_tags,
        active: response?.data?.data?.active_tags,
      })
      let data = response?.data?.data?.users?.map((item) => {
        return {
          key: item?._id,
          name: item?.name ?? "-",
          email: item?.email ?? "-",
          avatar: item?.avatar ?? ""
        }
      })
      setUsers(data);
    }
    const failureCb = (response) => {
      setLoading(false)
      setUsers([])
    }
    getDataCallContact("/admin/get_dashbord_data", successCb, failureCb);
  }

  // columns for the table //
  const columns = [
    {
      title: "Name",
      dataIndex: 'name',
      key: 'name',
      render : (text) => capitalizeFirstLetter(text)
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: 'email'
    },
  ]
  return (
    <>
      {loading && <Spin size="large" className='loader' />}
      {/* overview section */}
      <div>
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} className='p-2'>
            <Row className='justify-content-around'>
              <Col xs={24} sm={23} md={20} lg={7} className='p-4 Maincard m-0 mb-2'>
                <div className='mt-2 fw-bold font_size'>Total Users</div>
                <div className='mb-2'><img src={line} alt="" style={{ width: "100%" }} /></div>
                <div className='fw-bold fs-3'><img src={user} alt="" style={{ width: '44px' }} />&nbsp;&nbsp;{totalValues?.users}</div>
                <div className='mt-2 cursor-pointer' style={{cursor: "pointer" }} onClick={()=>getData()}><img src={refresh} alt="" style={{ width: '15px'}} /> <img src={refresh1} alt="" style={{ width: "48px" }} /></div>
              </Col>
              <Col xs={24} sm={23} md={20} lg={7} className='p-4 Maincard mb-2'>
                <div className='mt-2 fw-bold font_size'>Total  Tags</div>
                <div className='mb-2'><img src={line} alt="" style={{ width: "100%" }} /></div>
                <div className='fw-bold fs-3'><img src={calender} alt="" style={{ width: '44px' }} />&nbsp;&nbsp;{totalValues?.tags}</div>
                <div className='mt-2 cursor-pointer' style={{cursor: "pointer" }} onClick={()=>getData()}><img src={refresh} alt="" style={{ width: '15px' }} /> <img src={refresh1} alt="" style={{ width: "48px" }} /></div>
              </Col>
              <Col xs={24} sm={23} md={20} lg={7} className='p-4 Maincard mb-2'>
                <div className='mt-2 fw-bold font_size'>Active Tags</div>
                <div className='mb-2'><img src={line} alt="" style={{ width: "100%" }} /></div>
                <div className='fw-bold fs-3'><img src={calender} alt="" style={{ width: '44px' }} />&nbsp;&nbsp;{totalValues?.active}</div>
                <div className='mt-2 cursor-pointer' style={{cursor: "pointer" }} onClick={()=>getData()}><img src={refresh} alt="" style={{ width: '15px' }} /> <img src={refresh1} alt="" style={{ width: "48px" }} /></div>
              </Col>

            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} className='p-3'>
                <h4 className='fw-bold my-2'>Active Tags</h4>
                <img src={map} alt="" className='w-100' />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} className='p-2 my-2 Maincard'>
            <div className='mt-2 fw-bold font_size px-2 pt-3'>New Users</div>
            <div className='mb-2 text-center'><img src={line} alt="" style={{ width: "94%" }} /></div>
            {!loading && <Table
              dataSource={users}
              columns={columns}
              pagination={false}
              scroll={{ x: "250px" }} />}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Overview
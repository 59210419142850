import { Avatar, Button, Col, Row, Table, Modal, Spin, Popconfirm, message } from 'antd'
import React, { useEffect, useState } from 'react'
import girlPhoto from '../assets/images/girl.png'
import logoDel from '../assets/images/delete.png'
import { Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { callDelete, getDataCall, postCall } from '../components/CommonApiCall';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from '../utils/UtilityFunctions';
const { Search } = Input;
const blankData = []

const dataSource = [
  {
    key: '1',
    Users: 'Mike',
    Email: 'mike@mike@gmail.com',
    PhoneNumber: '9876543210',
    Subscription: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    key: '2',
    Users: 'Mike',
    Email: 'mike@mike@gmail.com',
    PhoneNumber: '9876543210',
    Subscription: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    key: '2',
    Users: 'Mike',
    Email: 'mike@mike@gmail.com',
    PhoneNumber: '9876543210',
    Subscription: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    key: '3',
    Users: 'Mike',
    Email: 'mike@mike@gmail.com',
    PhoneNumber: '9876543210',
    Subscription: "Lorem Ipsum is simply dummy text of the printing",
  },
  {
    key: '4',
    Users: 'Mike',
    Email: 'mike@mike@gmail.com',
    PhoneNumber: '9876543210',
    Subscription: "Lorem Ipsum is simply dummy text of the printing",
  },

]

function UserReporting() {
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState();
  const [users, setUsers] = useState([]);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [block, setBlock] = useState();
  const [delId, setDelId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    // pageSizeOptions: ['5', '10', '25', '50', '100'],
    // showSizeChanger: true,
    position: ['bottomRight'],
  });

  const columns = [
    {
      title: 'Users',
      dataIndex: 'name',
      key: 'name',
      align: "center",
      render: (text) => capitalizeFirstLetter(text)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: "center"
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      align: "center",
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription',
      align: "center",
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Button type="primary" danger className='mr-lg-2 mr-md-2 px-lg-3 mb-md-1' style={{ width: "5rem", marginRight: "0.5rem" }} onClick={() => handleDelete(record?.key)}>Delete</Button>

            <Button type="primary" className='px-3 purpleButton' style={{ width: "5rem", marginRight: "0.5rem" }} onClick={() => handleBlock(record)}>{record?.status == "1" ? "Block" : "Unblock"}</Button>
          </>)
      },
    },
  ];

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      console.log("get user list", response)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.other?.total_entries,
      }));
      let data = response?.data?.data.map((item) => {
        return {
          key: item?._id,
          name: item?.name ?? "-",
          email: item?.email ?? "-",
          phone: item?.phone.length > 0 ? item?.phone : "--",
          subscription: item?.subscription ?? "",
          status: item?.status
        }
      })
      setUsers(data);
    }
    const failureCb = (response) => {
      setLoading(false)
      setUsers([])
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getDataCall(pagination?.current, pagination?.pageSize, searchText, "/admin/get_user_list", successCb, failureCb);
  }

  const handleDelete = (id) => {
    console.log("delete handle", id)
    setDelId(id)
    setOpenDelModal(true)
  }
  const handleBlock = (record) => {
    console.log("block data", record)
    setBlock(record)
    setOpenBlockModal(true)
  }
  const updateData = (data) => {
    let url = `/admin/update_user_status`
    const successCb = (res) => {
      setLoading(false)
      console.log("delete", res)
      getData()
      message.success(res?.data?.message)
      setBlock('');
      setOpenBlockModal(false)
      setDelId(null)
      setOpenDelModal(false)
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }

  const onDeleteOk = () => {
    setLoading(true)
    let data = {
      _id: delId,
      status: "2",
    }
    updateData(data)
  }
  const onBlockeOk = () => {
    setLoading(true)
    let status;
    if (block?.status == "1") status = "0"
    else status = "1"
    let data = {
      _id: block?.key,
      status,
    }
    updateData(data)
  }

  // Triggered on Change Page Number
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };

  console.log("openDel modal", openDelModal)

  const onSearch = (value, _e, info) => {
    console.log("search",info?.source, value);
    setSearchText(value)
  }

  return (
    <div className='p-2'>
      {loading && <Spin size="large" className='loader' />}
      {/* {!openUserDetails ? */}
      <>
        <div className='d-flex justify-content-between flex-column flex-md-row mb-2'>
          <h5 className='fw-bold'>Users List</h5>
          <Search
            placeholder="Search user"
            // addonBefore={<SearchOutlined />}
            allowClear
            onSearch={onSearch}
            style={{maxWidth : "400px"}}
          />
        </div>
        {!loading && <Table
          dataSource={users}
          columns={columns}
          scroll={{ x: "250px" }}
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }} />}
      </>

      <Modal
        centered
        open={openDelModal}
        onCancel={() => { setOpenDelModal(false); setDelId(null) }}
        footer={null}
        width={350}
      >
        <div className='text-center'>
          <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
        </div>
        <h5 className='text-center'>Delete User</h5>
        <p>Are you sure you want ot Delete this User?</p>
        <div>
          <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk} >Delete</Button>
          <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDelModal(false); setDelId('') }}>Cancel</Button>
        </div>
      </Modal>
      <Modal
        centered
        open={openBlockModal}
        onCancel={() => { setOpenBlockModal(false); setBlock(null) }}
        footer={null}
        width={350}
      >
        <h5 className='text-center mt-4'>{block?.status == "1" ? "Block User" : "Unblock User"}</h5>
        <p>Are you sure you want ot {block?.status == "1" ? "block" : "unblock"} this User?</p>
        <div className='mb-4'>
          <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onBlockeOk} >{block?.status == "1" ? "Block" : "Unblock"}</Button>
          <Button danger type="primary" className='w-48 ' onClick={() => { setOpenBlockModal(false); setBlock('') }}>Cancel</Button>
        </div>
      </Modal>

    </div>
  )
}

export default UserReporting